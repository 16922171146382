import "./index.scss"

import { Col, Row } from "react-simple-flex-grid"

import { FormattedMessage } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import Particles from "../components/particles"
import React from "react"
import SEO from "../components/seo"

const IndexPage = () => {
  return (
    <Layout>
      <SEO keywords={[]} />

      <div>
        <Row className="Home__Container">
          <Col span={11} offset={1} className="Home__Wrapper">
            <h1 className="Text__H1 Text__White Text__Regular" style={{ marginBottom: "32px" }}>
              <FormattedMessage id="hello" /> <span className="Text__Bold">Joffrey</span>.
            </h1>
            <p className="Text__H2 Text__Darken Text__Light">
              <FormattedMessage id="job" />
            </p>
            <p className="Text__H2 Text__Darken Text__Light">
              <FormattedMessage id="currentlyAt" />{" "}
              <a
                className="Text__Accent Text__Bold Text__Underline Company__Link"
                href="https://www.stack-labs.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                STACK LABS
              </a>
            </p>
            <p className="Text__H2 Text__Darken Text__Light">Toulouse, France</p>
          </Col>
        </Row>

        <Particles />
      </div>
    </Layout>
  )
}

export default IndexPage
